<template>
  <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 25 33">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          v-bind:style="{ fill: svg.fill, stroke: svg.stroke, strokeWidth: '1px'}"
          d="M12.5,0A13.07,13.07,0,0,0,3.66,3.38,11.16,11.16,0,0,0,0,11.55a12.59,12.59,0,0,0,2.34,7.26L12.5,33,22.66,18.81A12.5,12.5,0,0,0,25,11.55a10.75,10.75,0,0,0-.95-4.42,11.68,11.68,0,0,0-2.71-3.75A12.62,12.62,0,0,0,17.28.88,13.37,13.37,0,0,0,12.5,0Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
const props = {
  colour: {
    type: String,
    default: ''
  },
  highlight: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'MapMarker',
  props,
  data: () => ({
    svg: {
      fill: '',
      stroke: 'none',
      strokeWidth: ''
    }
  }),
  mounted() {
    this.colour ? (this.svg.fill = this.colour) : (this.svg.fill = '#225e97')
    if (this.highlight) {
      this.svg.stroke = 'var(--dro-primary)'
      this.svg.strokeWidth = '4px'
    }
  },
  watch: {
    colour(v) {
      this.svg.fill = v
    }
  }
}
</script>

<style scoped>
.marker {
  height: 40px;
  width: 40px;
}
</style>
